import axios from "axios";
import { IDelete, IDeleteItem } from '../Models/IDelete';
import jwt_decode from "jwt-decode";
import { AccessType, IAppUserRole } from '../Models/IAppUserRole';
import { Constants } from './Constants'
import { QualityCheckFormType } from '../Models/QualityCheckFormType';
import { countBy } from 'lodash'
import configuration from '../components/ConfigLoader/Config'
import appsettings from '../components/AppSettings/AppSettings';

var query_builder_seed = 0;
export class Utilities {

    public IsRMA_ADJUSTMENT_TABLE(tableName?: string) {
        if (this.isEmpty(tableName) || tableName == undefined)
            return false;

        return (tableName.toLocaleUpperCase() == "RMA_ADJUSTMENT_NEW" || tableName.toLocaleUpperCase() == "RMA_ADJUSTMENT");
    }

    public IsRMA_QUOTEDATE_TABLE(tableName?: string) {
        if (this.isEmpty(tableName))
            return false;

        return this.compare(tableName, Constants.rmaTables.quotedate);
    }

    public count = (str: string, ch: any) => countBy(str)[ch] || 0;

    public getAppSetting(key: string, defaultValue: any = null) {
        return appsettings[key] || defaultValue;
    }

    public uuid() {
        // Generate a random GUID http://stackoverflow.com/a/2117523.
        const timePart = (new Date().getTime().toString(16) + "FF").substr(0, 11);
        const s = "ssssssss-xxxx-4xxx-yxxx-x".replace(/[xys]/g, (c) => {
            const r = c === "s" ? Math.floor(Math.random() * 16) : (query_builder_seed++) & 0xf;
            const v = c === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
        return s + timePart;
    }

    public deepClone(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    public isEmpty(val: any): boolean {
        return (val == undefined || val == null ||
            (typeof (val) == 'string' && (val == '' || val.trim() == '')));
    }

    public compare(val?: string, val2?: string): boolean {
        if (val == null || val == undefined || val2 == null || val2 == undefined)
            return false;
        return val.toLowerCase() == val2.toLowerCase()
    }

    ///<capitalizeFLetter> Capitalize first letter in string
    public capitalizeFLetter(inputValue: string): string {
        return inputValue && inputValue[0].toUpperCase() + inputValue.slice(1);
    }

    public async getTableData(schemaName: string, tblName: string) {
        console.log("getTableData - schemaName: %s", schemaName);
        console.log("getTableData - tblName: %s", tblName);
        let tblDataUrl = `${Constants.hostURL()}/${Constants.controller.dynamicData}/${Constants.actions.getTableData}?tableName=${tblName}&schemaType=${schemaName}`;
        return new Promise<any>((resolve: (items: any) => void, reject: (error: any) => void): void => {
            this.getDataFromDB(tblDataUrl, {}).then((tblData: any) => {
                if (tblData)
                    resolve(tblData);
                else
                    reject("Get Table Data Error");
            });
        });
    }

    async downloadFile(id: number, fileName: string, isOutFile: boolean = true) {
        try {
            const apiURL = `${Constants.hostURL()}/${Constants.controller.dynamicData}/DownloadFile?id=${id}&isOutputFile=${isOutFile}`;
            const result = await this.getFile(apiURL);

            const url = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}`);
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            console.log(e);
        }
    }

    ///<getDataFromDB> API- Get Data from DB
    public getDataFromDB(url: string, config: any) {

        return this.checkIfTokenExpiredAndRefresh().then(() => {
            console.log("getDataFromDB: Promise");
            return new Promise<any>((resolve: (items: any) => void, reject: (error: any) => void): void => {
                let data;
                axios.get(url, config)
                    .then(response => {
                        if (response && response.data && response.data.length === 0) {
                            resolve([]);
                        }
                        else {
                            resolve(response.data);
                        }
                        console.log(response);
                    })
                    .catch(error => {
                        reject(error);
                        console.log(error);
                    });
                return data;
            });
        });
    }

    ///<patchDataToDB> API- Insert/Update data to DB
    public patchDataToDB(url: string, patchTable: any) {
        return this.checkIfTokenExpiredAndRefresh().then(() => {
            console.log("patchDataToDB: Promise");
            console.log("patchDataToDB - url: %s", url);
            console.log("patchDataToDB - patchTable:");
            console.log(patchTable);
            return new Promise<any>((resolve: (items: any) => void, reject: (error: any) => void): void => {
                let data;
                console.log("About to call Axios.Put...");
                axios.put(url, patchTable)
                    .then(response => {

                        console.log("Response found: ", response.data);

                        if (response && response.data && Object.keys(response.data).length <= 0) {
                            resolve([]);
                        }
                        else {
                            resolve(response.data);
                        }
                        console.log(response);
                    })
                    .catch(error => {
                        console.log("patchDataToDB - Error: ");
                        reject(error);
                        console.log(error);
                    });
                return data;
            });
        });
    }

    ///<patchDataToDB> API- Insert/Update data to DB
    public postDataToDB(url: string, patchTable: any) {
        return this.checkIfTokenExpiredAndRefresh().then(() => {
            console.log("postDataToDB: Promise");
            console.log("postDataToDB - url: %s", url);
            console.log("postDataToDB - patchTable:");
            console.log(patchTable);
            return new Promise<any>((resolve: (items: any) => void, reject: (error: any) => void): void => {
                let data;
                console.log("Assembling data to post...");
                axios.post(url, patchTable)
                    .then(response => {

                        if (response && response.data && Object.keys(response.data).length <= 0) {
                            console.log("Response found, but empty");
                            resolve([]);
                        }
                        else {
                            console.log("Response found: ", response.data);
                            resolve(response.data);
                        }
                        console.log(response);
                    })
                    .catch(error => {
                        reject(error);
                        console.log(error);
                    });
                return data;
            });
        });
    }

    public async getFile(url: string) {
        return this.checkIfTokenExpiredAndRefresh().then(() => {
            console.log("getFile: Promise");
            return new Promise<any>((resolve: (items: any) => void, reject: (error: any) => void): void => {
                let data;
                axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob',
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                        console.log(error);
                    });
                return data;
            });
        });
    }


    ///<deleteDataFromDB> API- Delete data from DB
    public deleteDataFromDB(url: string, delObj: IDelete) {
        return this.checkIfTokenExpiredAndRefresh().then(() => {
            console.log("deleteDataFromDB: Promise");
            return new Promise<any>((resolve: (items: any) => void, reject: (error: any) => void): void => {
                let data;
                axios.put(url, delObj)
                    .then(response => {

                        if (response && response.data && Object.keys(response.data).length <= 0) {
                            resolve([]);
                        }
                        else {
                            resolve(response.data);
                        }
                        console.log(response);
                    })
                    .catch(error => {
                        reject(error);
                        console.log(error);
                    });
                return data;
            });
        });
    }


    ///<deleteDataFromDB> API- Delete data from DB
    public deleteItemFromDB(url: string, DelObj: IDeleteItem) {
        return this.checkIfTokenExpiredAndRefresh().then(() => {
            return new Promise<any>((resolve: (items: any) => void, reject: (error: any) => void): void => {
                let data;
                axios.delete(url, { data: { ID: DelObj.ID } })
                    .then(response => {

                        if (response && response.data && Object.keys(response.data).length <= 0) {
                            resolve([]);
                        }
                        else {
                            resolve(response.data);
                        }
                        console.log(response);
                    })
                    .catch(error => {
                        reject(error);
                        console.log(error);
                    });
                return data;
            });
        });
    }

    ///<deleteDataFromDB> API- Apply RMA
    public runJob(inputObj: any) {
        let api_url = `${Constants.hostURL()}/${Constants.controller.dynamicData}/${Constants.actions.runJob}`;


        return this.checkIfTokenExpiredAndRefresh().then(() => {
            return new Promise<any>((resolve: (items: any) => void, reject: (error: any) => void): void => {
                let data;
                axios.post(api_url, inputObj)
                    .then(response => {

                        if (response && response.data && Object.keys(response.data).length <= 0) {
                            resolve([]);
                        }
                        else {
                            resolve(response.data);
                        }
                        console.log(response);
                    })
                    .catch(error => {
                        reject(error);
                        console.log(error);
                    });
                return data;
            });
        });
    }

    public async getJobRunStatus(runId: number, msgId: any) {
        let api_url = `${Constants.hostURL()}/${Constants.controller.dynamicData}/GetJobRunStatus?runId=${runId}&msgId=${msgId}`;

        const token = await this.checkIfTokenExpiredAndRefresh()
        const result = await axios.get(api_url);
        return result.data;
    }

    public getCurrentDateTime = (): string => {
        return new Date().toISOString().replace("Z", "").replace("T", " ").split(".")[0];
    }

    public getFormatedDateTime = (datetime: any): string => {
        return datetime.replace("Z", "").replace("T", " ").split(".")[0];
    }

    public getFormatedDate = (datetime: Date): string => {
        var mm = datetime.getMonth() + 1; // getMonth() is zero-based
        var dd = datetime.getDate();

        return [datetime.getFullYear(), "-",
        (mm > 9 ? '' : '0') + mm, "-",
        (dd > 9 ? '' : '0') + dd
        ].join('');
    };

    public checkIfTokenExpiredAndRefresh = async (): Promise<any> => {
        console.log("checkIfTokenExpiredAndRefresh...");
        const authToken = localStorage.getItem("AuthToken");
        const refreshToken = localStorage.getItem("refreshToken");
        if (authToken !== null && refreshToken !== null) {
            //const originalRequest = error.config;

            let decodedAuthToken: any = jwt_decode(authToken);
            console.log("decodedAuthToken: ", decodedAuthToken);
            let expiry = decodedAuthToken.exp;
            var tokenExpiryDateTime = new Date(expiry * 1000);
            console.log("tokenExpiryDateTime: ", tokenExpiryDateTime);
            if (tokenExpiryDateTime <= new Date()) {
                console.log("Token Expired");
                const refreshObj: any = {
                    refresh_token: refreshToken,
                    grant_type: "refresh_token",
                    client_id: String(configuration.REACT_APP_OAUTH_CLIENTID)
                };

                //Iterating through the keys to encode information inside refreshobj//
                const data = Object.keys(refreshObj)
                    .map((key, index) => `${key}=${encodeURIComponent(refreshObj[key])}`)
                    .join('&');

                axios.post(String(configuration.REACT_APP_OAUTH_TOKEN_URL), data, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }).then(res => {
                    localStorage.setItem("AuthToken", "Bearer " + res.data.access_token);
                    localStorage.setItem("refreshToken", res.data.refresh_token);
                    console.log("Token Refreshed");
                    console.log("Token access_token: ", res.data.access_token);
                    console.log("Token refresh_token: ", res.data.refresh_token);
                    //window.location.reload();
                }).catch(error => {
                    console.log("tokenExpiryDateTime - Error:");
                    console.log(error);
                    sessionStorage.setItem("callback", "false");
                    window.location.reload();
                });

                await this.delay(10000).then(() => {
                    console.log("Token Refreshed after delay");
                });
            }
        }
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public parseJson(str: string, defaultValue: any = {}) {
        let value = defaultValue;
        try {
            value = JSON.parse(str);
        } catch (e) {
            console.log(e);
        }
        return value;
    }

    public getCurrentLoggedInUserName() {
        if (sessionStorage.getItem("LoggedInUserName") !== null) {
            return sessionStorage.getItem("LoggedInUserName");
        } else {
            return Constants.defaultValue.user;
        }
    }

    private addAuditColumns = (dataObj: any): any => {
        dataObj[Constants.auditColumns.CreateBy] = this.getCurrentLoggedInUserName();
        dataObj[Constants.auditColumns.Created] = this.getCurrentDateTime();
        dataObj[Constants.auditColumns.ModifiedBy] = this.getCurrentLoggedInUserName();
        dataObj[Constants.auditColumns.Modified] = this.getCurrentDateTime();
        return dataObj;
    }

    public updateAuditColumns = (currentRowData: any, formType: QualityCheckFormType = QualityCheckFormType.New): any => {
        const currentTime = this.getCurrentDateTime();
        const loggedInUser = this.getCurrentLoggedInUserName();

        if (formType == QualityCheckFormType.New) {
            currentRowData[Constants.auditColumns.CreateBy] = loggedInUser;
            currentRowData[Constants.auditColumns.Created] = currentTime;
        }
        currentRowData[Constants.auditColumns.ModifiedBy] = loggedInUser;
        currentRowData[Constants.auditColumns.Modified] = currentTime;

        if (currentRowData.hasOwnProperty('updated_date')) {
            currentRowData['updated_date'] = currentTime;
        }
        if (currentRowData.hasOwnProperty('updated_by')) {
            currentRowData['updated_by'] = loggedInUser;
        }

        return currentRowData;
    }
}